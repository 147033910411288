
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import DssIcon from '@/components/atoms/DssIcon.vue'
import ToPageTop from '@/components/organisms/dms/common/ToPageTop.vue'

export default defineComponent({
  name: 'AuthorComplete',
  components: {
    DssIcon,
    ToPageTop,
  },
  props: {
  },
  setup () {
    const router = useRouter()

    const store = useStore()
    const openinqSearch = store.getters.openinqSearch

    return {
      router,
      openinqSearch,
    }
  },
})
