
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import AuthorComplete from '@/components/organisms/dms/AuthorComplete/AuthorComplete.vue'

export default defineComponent({
  components: {
    AuthorComplete,
  },
  setup () {
    const router = useRouter()
    return {
      router,
    }
  },
})
